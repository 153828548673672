import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Header from '../components/Header'
import { Link } from 'gatsby'

class SubmitSuccess extends React.Component {
  render() {

    return (
      <Layout title="Thank you" >
        <Header title="Thank you" />
        <div id="main">
          <section id="content" className="main align-center">
            <h2>You've successfully submitted your information!</h2>
            <Link to="/">Back to Home.</Link>
          </section>
        </div>
      </Layout>
    )
  }
}

export default SubmitSuccess;
